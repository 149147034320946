import React, { useState,useEffect } from "react";
import { Col, Row } from "reactstrap";
import styles from './poolpage.module.scss';
import PoolCard from "../../components/poolCard/poolCard"; 
import bcaLogo from "../../images/bca_logo.png";
import zenLogo from "../../images/zen_logo.png";
import harmonyLogo from "../../images/harmonyLogo.png";
import logo from "../../images/logo.png";

import twinLogo from "../../images/twin.png";
import soonLogo from "../../images/soon.png";
import maruLogo from "../../images/Riyoku-01.png";
import bigfootLogo from "../../images/bigfoot.png";
import revolverLogo from "../../images/revolver-logo.png";
import portifyLogo from "../../images/portify.png";
import LemonLogo from "../../images/LemonLogo.png";
import HeronLogo from "../../images/HeronLogo.png";
import GoldYarloologo from "../../images/GoldYarloologo.png";
import WolfLogo from "../../images/GUARDIANS-TOKEN-SHIELD-LOGO.png";
import BirbLogo from "../../images/birb-logo.svg";
import ClosedSeaLogo from "../../images/ClosedSeaLogo.png";
import SayerLogo from "../../images/SayerLogo.png";
import FGSPORTLogo from "../../images/FGSPORT.jpg";
import FANTOMNOMICS from "../../images/FANTOMNOMICS.png";
import CakeToolLogo from "../../images/CakeToolLogo.png";
import TOREKO from "../../images/TOREKO.jpg";
import PS from "../../images/PSLOGO.png";
import DRATE from "../../images/DRATE.png";
import DKS from "../../images/DKS.png";
// import PS from "../../images/PSLOGO.png";
import EXIP from "../../images/EXIP.png";
import BELLY from "../../images/BELLY.png";
import ARI from "../../images/ARI.png";
import LC from "../../images/LC.png";

const PoolsPage = () => {

  const [] = useState();

  const SampleData = [
    {
      id: 29,
      description:"Light Year is a blockchain based space strategy game. Mining natural resources, crafting space ships, battling and trading with other players, joining alliances or nations, the game brings players the wildest experience of space exploration. Moreover, under the hood, it's a play-to-earn DeFi farming protocol that maximizes users' return from staking their crypto assets.",
      status: "TBA",
      currencyRateType:"BUSD/LC",
      currencyRate: "1 BUSD = 66 $LC",
      cap: "100k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/100000",
      website:"https://lightyear.game/home",
      twitterLink:"https://twitter.com/lightyear_game",
      telegramLink:"https://t.me/lightyeargroup",
      discordLink:"#",
      mediumLink: "#",
      ido: "0xaEF7A10B13059068E9B6Ecad32ccc5d8e9A2e491",
      logo: LC
    },
   
    {
      id: 28,
      description:"Manarium is a platform designed to create opportunities for mini game developers. It is the place where gamers can have fun and earn tokens and investors can get early access to game projects.",
      status: "TBA",
      currencyRateType:"BUSD/ARI",
      currencyRate: "1 BUSD = 25 $ARI",
      cap: "100k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/100000",
      website:"https://www.manarium.com/",
      twitterLink:"https://twitter.com/manarium_gg?s=21",
      telegramLink:"https://t.me/Manarium_Chat",
      discordLink:"#",
      mediumLink: "#",
      ido: "0x0475AD4E7Fcb26B2920Ce222379F5f4D28D10C12",
      logo: ARI
    },
   
    {
      id: 27,
      description:"CryptoPiece is a NFT game that focuses on gamers, made by gamers and for gamers. We want to have our players not only feeling relax, enjoying their favorite game, but also earning at the same time.",
      status: "TBA",
      currencyRateType:"BUSD/BELLY",
      currencyRate: "1 BUSD = 200 $BELLY",
      cap: "100k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/100000",
      website:"https://cryptopiece.online/",
      twitterLink:"https://twitter.com/CryptoPieceNFT",
      telegramLink:"https://t.me/cryptopiece_official",
      discordLink:"#",
      mediumLink: "#",
      ido: "0x2d73CB14F5aE5aD453f46c9FeDeFB5E38b4Ecf48",
      logo: BELLY
    },
   
    {
      id: 26,
      description:"A Decentralized Internet Governed By Community EXIP introduces a decentralized Internet with no central governing body. We believe that nobody reserves the right to dictate the primary operations of the internet. EXIP returns 100 % ownership & control of domains and TLDs to the registrants for a single lifetime fee.",
      status: "TBA",
      currencyRateType:"BUSD/EXIP",
      currencyRate: "1 BUSD = 0.055 $EXIP",
      cap: "150k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/150000",
      website:"https://exip.live/",
      twitterLink:"https://twitter.com/ExipOfficial",
      telegramLink:"https://t.me/exipofficial",
      discordLink:"#",
      mediumLink: "#",
      ido: "0xA540289b561fdf7eC16375B93136643BF5f5a5b4",
      logo: EXIP
    },
   
    {
      id: 25,
      description:"DARKSHIELD GAMES A COLLECTION OF MULTI-CHAIN CRYPTO GAMES At DarkShield Game Studio our games utilise Crypto and NFT as the reward system served by a Gaming MarketPlace.",
      status: "Open",
      currencyRateType:"BUSD/DKS",
      currencyRate: "1 BUSD = 200 $DKS",
      cap: "125k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/125000",
      website:"https://darkshield.games/",
      twitterLink:"https://twitter.com/DarkshieldG",
      telegramLink:"https://t.me/DarkShieldGamesStudio",
      discordLink:"#",
      mediumLink: "#",
      ido: "0xaBE879E233ff50fCCD9e735b1a5b217Cf8725920",
      logo: DKS
    },
    {
      id: 24,
      description:"Cheesus let you Discover all malicious bots and dumpers Find amount of REAL holders and traders in every project Tax Calculation.",
      status: "Open",
      currencyRateType:"BUSD/DRATE",
      currencyRate: "1 BUSD = 26.31 $DRATE",
      cap: "75k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/75000",
      website:"https://cheesus.ai/",
      twitterLink:"https://twitter.com/CheesusDeFi",
      telegramLink:"ttps://t.me/cheesus_MAIN_eng",
      discordLink:"#",
      mediumLink: "#",
      ido: "0xF0FF196B7514879a28C96C33783aad6D93039040",
      logo: DRATE
    },
   
  
    {
      id: 23,
      description:"PIXEL SQUID is  An 100% New Metaverse Project GameFi x DeFI x NFT. Players can open the mystery NFT box and trade with others in the marketplace. They could challenge the different levels in the game just like the same scenario in the TV show.",
      status: "Open",
      currencyRateType:"BUSD/PS",
      currencyRate: "1 BUSD = 454.54 $PS",
      cap: "352k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/352000",
      website:"https://pixelsquid.site/",
      twitterLink:"https://twitter.com/PixelSquidBSC",
      telegramLink:"https://t.me/PixelSquidBSC",
      discordLink:"#",
      mediumLink: "#",
      ido: "0xbaE4e5E51fC40579AAd8d5Cd96E06b4f23345a9d",
      logo: PS
    },
    
    {
      id: 22,
      description:"TOREKKO is an ecosystem to decentralize the world of japanimation. We start with NFTcollectibles related to jap-animation and manga. The jap-animation licences are at the heart of the TOREKKO project.",
      status: "Open",
      currencyRateType:"BUSD/TRK",
      currencyRate: "1 BUSD = 7.69 $TRK",
      cap: "150k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/150000",
      website:"https://torekko.com/",
      twitterLink:"https://twitter.com/torekkocom?s=20",
      telegramLink:"https://t.me/torekko",
      discordLink:"#",
      mediumLink: "#",
      ido: "0x002a6FcEd6C3Ab8635552AFFC8263ea9292C0247",
      logo: TOREKO
    },
   
    // {
    //   id: 21,
    //   description:"Fantomnomics is a rebase dividend yield token, which redestribute Fantom (Bep-20)",
    //   status: "Open",
    //   currencyRateType:"BUSD/FTMNOM",
    //   currencyRate: "1 BUSD = 80000 $FTMNOM",
    //   cap: "50k",
    //   acess: "Dynamic",
    //   participants: "0",
    //   percentage: "0",
    //   completion: "0/50000",
    //   website:"https://fantomnomics.com/",
    //   twitterLink:"https://twitter.com/Fantomnomics",
    //   telegramLink:"https://t.me/FantomnomicsBSC",
    //   discordLink:"#",
    //   mediumLink: "#",
    //   ido: "0x901a6dF02fB15DA218D7F9a330cC3504807Da7cd",
    //   logo: FANTOMNOMICS
    // },
    {
      id: 20,
      description:"Cake Tools provides the community with data aggregated from all decentralized exchanges on Binance Smart Chain.",
      status: "Open",
      currencyRateType:"BUSD/CKT",
      currencyRate: "1 BUSD = 15.15 $CKT",
      cap: "350k",
      acess: "Dynamic",
      participants: "0",
      percentage: "0",
      completion: "0/100000",
      website:"https://caketools.io/",
      twitterLink:"https://twitter.com/cake_tools",
      telegramLink:"https://t.me/caketoolsio",
      discordLink:"#",
      mediumLink: "#",
      ido: "0xeaB3822Ed2FA995Cd254793c9EF98921a5e1c9d8",
      logo: CakeToolLogo
    },
   

    {
      id: 19,
      description:"In the parallel timeline exists the FootballMetaverse planet, belonging to the Cyberball galaxy, of the Vitural Milky Way which is 65 million years of technology from Earth.",
      status: "Open",
      currencyRateType:"BUSD/FGSPORT",
      currencyRate: "1 BUSD = 500 $FGSPORT",
      cap: "100k",
      acess: "Dynamic",
      participants: "325 USD",
      percentage: "0",
      completion: "0/100000",
      website:"https://footballgo.io/",
      twitterLink:"https://twitter.com/FootballGobsc",
      telegramLink:"https://t.me/FootballGobsc",
      discordLink:"#",
      mediumLink: "#",
      ido: "0x3e9775969D598B892cF011bb79E130d2171DF6cA",
      logo: FGSPORTLogo
    },
   
    {
      id: 18,
      description:"RO Slayers is a community-driven game that brings inclusivity and social responsibility to the forefront of an already exciting gaming experience. RO Slayers is a fun, exciting online game that serves a double purpose: it brings entertainment and brings in donations to save kids.",
      status: "Open",
      currencyRateType:"BUSD/SLYR",
      currencyRate: "1 BUSD = 5 $SLYR",
      cap: "110k",
      acess: "Dynamic",
      participants: "325 USD",
      percentage: "0",
      completion: "0/110000",
      website:"https://roslayers.com/",
      twitterLink:"https://twitter.com/RoSlayers",
      telegramLink:"https://t.me/roslayers",
      discordLink:"#",
      mediumLink: "#",
      ido: "0xa7721eCEF44DE8f3dF837A6cb356a5945e745568",
      logo: SayerLogo
    },

    {
      id: 16,
      description:"Find jobs on Binance Smart Chain. Welcome to Lemonn, the most comprehensive job searching platform on BSC. Lemonn Token offers premium advantages for job seekers and recruiters",
      status: "Open",
      currencyRateType:"BUSD/LMN",
      currencyRate: "1 BUSD = 20 $LMN",
      cap: "200k",
      acess: "Dynamic",
      participants: "300 USD",
      percentage: "0",
      completion: "0/200000",
      website:"https://lemonn.io/",
      twitterLink:"https://twitter.com/LemonnApp",
      telegramLink:"https://t.me/LemonnApp",
      discordLink:"#",
      mediumLink: "#",
      ido: "0xBE5a21971669EC2022C635E16cf87cda2c8F9f18",
      logo: LemonLogo
    },
   
    {
      id: 15,
      description:"Since 2020, LaunchZone has nurtured an idea of a sustainable wellness and investment solution. That is how Heron Eco Retreat was born.",
      status: "Open",
      currencyRateType:"BUSD/HERON",
      currencyRate: "1 BUSD = 66.66 $HERON",
      cap: "150k",
      acess: "Dynamic",
      participants: "325 USD",
      percentage: "0",
      completion: "0/150000",
      website:"https://heron.asia/",
      twitterLink:"https://twitter.com/Heronasia",
      telegramLink:"https://t.me/heronasia",
      discordLink:"#",
      mediumLink: "#",
      ido: "0x91C5891E52B7bdF2af5e1adFe082aed5631f5156",
      logo: HeronLogo
    },
 
    
    {
      id: 17,
      description:"Closedsea is designed to offer a multi-chain experience across all blockchains that support smart contracts such as; BSC, POLYGON, ETH, SOL, and more.",
      status: "Open",
      currencyRateType:"BUSD/SEA",
      currencyRate: "1 BUSD = 5.55 $ SEA",
      cap: "250k",
      acess: "Private",
      participants: "0",
      percentage: "0%",
      completion: "0/250000",
      website:"https://closedsea.com/",
      twitterLink:"https://twitter.com/closedseanft",
      telegramLink:"https://t.me/closedseanft",
      discordLink:"#",
      mediumLink: "#",
      ido: "0x8c45bD848AC2bE9df5f42B979CD39Cfb8fe9cdEa",
      logo: ClosedSeaLogo
    },
  
 
    
 
    {
      id: 14,
      description:"Yarloo is a crypto and gaming veterans who have poured their passion into bringing a unique take on crypto NFT gaming, by empowering other P2E companies that want to provide amazing quality to their user-base.",
      status: "Closed",
      currencyRateType:"BUSD/YARL",
      currencyRate: "1 BUSD = 2.85 YARL",
      cap: "100k",
      acess: "Private",
      participants: "700",
      percentage: "100",
      completion: "100000/100000",
      website:"http://www.yarloo.io/",
      twitterLink:"https://twitter.com/Yarloo_RST",
      telegramLink:"https://t.me/yarloochat",
      discordLink:"#",
      mediumLink: "https://medium.com/@yarloo",
      ido: "#",
      logo: GoldYarloologo
    },
 
       {
      id: 13,
      description:"Your one-stop DeFi Social Platform Track, trade, discover, analyse and more all on one platform.",
      status: "Closed",
      currencyRateType:"BUSD/HPAD",
      currencyRate: "1 BUSD = 3030 HPAD",
      cap: "35k",
      acess: "Private",
      participants: "700",
      percentage: "100",
      completion: "35000/35000",
      website:"https://www.harmonypad.io/",
      twitterLink:"https://twitter.com/harmony_pad",
      telegramLink:"https://t.me/HarmonyPadAnn",
      discordLink:"#",
      mediumLink: "https://harmony-pad.medium.com/",
      ido: "#",
      logo: harmonyLogo
    },
 
    {
      id: 12,
      description:"Your one-stop DeFi Social Platform Track, trade, discover, analyse and more all on one platform.",
      status: "Closed",
      currencyRateType:"BUSD/PFY",
      currencyRate: "1 BUSD = 292 PFY",
      cap: "175k",
      acess: "Private",
      participants: "700",
      percentage: "100",
      completion: "175000/175000",
      website:"https://portify.app",
      twitterLink:"https://twitter.com/Portify_",
      telegramLink:"https://t.me/Portify",
      discordLink:"#",
      mediumLink: "https://medium.com/@portify",
      ido: "#",
      logo: portifyLogo
    },
    {
      id: 11,
      description:"Hold $RVLVR Earn specially curated BSC Tokens daily.",
      status: "Closed",
      currencyRateType:"BUSD/RVLVER",
      currencyRate: "1 BUSD = 2000 RVLVER",
      cap: "176k",
      acess: "Public",
      participants: "700",
      percentage: "100",
      completion: "176000/176000",
      website:"https://www.revolvertoken.com/",
      twitterLink:"https://twitter.com/RevolverToken",
      telegramLink:"https://t.me/RevolverToken",
      discordLink:"#",
      mediumLink: "https://t.me/RevolverToken",
      ido: "#",
      logo: revolverLogo
    },
    
    {
      id: 10,
      description:"BIGFOOT is a decentralized community token with a hybrid dividend mechanism. Simply hold $FOOT and yield $BANANA (ApeSwap's native token).",
      status: "Closed",
      currencyRateType:"BUSD/FOOT",
      currencyRate: "1 BUSD = 24200 FOOT",
      cap: "165k",
      acess: "Public",
      participants: "700",
      percentage: "100",
      completion: "60000/60000",
      website:"https://bigfoottoken.finance/",
      twitterLink:"https://twitter.com/Bigfoottoken",
      telegramLink:"https://t.me/bigfoottoken",
      discordLink:"#",
      mediumLink: "#",
      ido: "#",
      logo: bigfootLogo
    },
 
    {
      id: 9,
      description:"The first decentralized educational cartoon tv show token ecosystem! Decentralized meme show token fueled by the $MARU and $RIYO token. Teaching the public about cryptocurrency concepts and news.",
      status: "Closed",
      currencyRateType:"BUSD/MARU",
      currencyRate: "1 BUSD = 2000 MARU",
      cap: "200k",
      acess: "Public",
      participants: "700",
      percentage: "100",
      completion: "60000/60000",
      website:"https://riyoku.tv/",
      twitterLink:"https://twitter.com/riyokutv",
      telegramLink:"https://t.me/RiyokuTVofficial",
      discordLink:"#",
      mediumLink: "#",
      ido: "#",
      logo: maruLogo
    },
    {
      id: 8,
      description:"Birb aims to become a feature-rich and usable cryptocurrency that rewards its holders. The Birb game portal will be live at launch. Birb will be a multi-use case token with Ecommerce, Social Sending, NFT Marketplace & More.",
      status: "Closed",
      currencyRateType:"BUSD/BIRB",
      currencyRate: "1 BUSD = 50 BIRB",
      cap: "275k",
      acess: "Public",
      participants: "700",
      percentage: "100",
      completion: "275000/275000",
      website:"https://birb.co/",
      twitterLink:"https://twitter.com/BirbDefi",
      telegramLink:"https://t.me/birbdefi",
      discordLink:"#",
      mediumLink: "https://medium.com/birb",
      ido: "#",
      logo: BirbLogo
    },

    {
      id: 7,
      description:"The WOLF DEN Presents GUARDIAN. The first decentralized token designed specifically to earn you passive income by unlocking high yield staking and farming opportunities on multiple platforms across the BSC Network.",
      status: "Closed",
      currencyRateType:"BUSD/GUARD",
      currencyRate: "1 BUSD = 151 GUARD",
      cap: "60k",
      acess: "Public",
      participants: "650",
      percentage: "100",
      completion: "60000/60000",
      website:"https://www.wolfdencrypto.com/",
      twitterLink:"https://twitter.com/WOLFIESTOKEN?s=09",
      telegramLink:"https://t.me/wolfiestoken",
      discordLink:"https://discord.gg/trSJUMVf",
      mediumLink: "https://medium.com/@WOLFIESTOKEN/wolfies-8892ce6165d",
      ido: "#",
      logo: WolfLogo
    },
 

    {
      id: 6,
      description:"We are a community driven project that has the right hype and growth potential, but also  includes great cause and utility that will bring massive returns to long-time hodlers. Now  available not only on Polygon, but also BSC network.",
      status: "Closed",
      currencyRateType:"BUSD/SOON",
      currencyRate: "1 BUSD = 2000 SOON",
      cap: "100k",
      acess: "Private",
      participants: "700",
      percentage: "100",
      completion: "67000/70000",
      website:"https://dogematic.link/",
      twitterLink:"https://twitter.com/Dogematic_",
      telegramLink:"https://t.me/DOGE_MATIC",
      discordLink:"https://discord.com/invite/Gd3fJyb9",

      closed:true,
      ido: "#",
      logo: soonLogo

    },

    {
      id: 5,
      description:
        "Twin is a DeFi protocol powered by smart contracts on the Binance Smart Chain network that enables the creation of synthetic assets called Twin Assets (T-Assets).",
        status: "Closed",
        currencyRateType:"BUSD/TWIN",
      currencyRate: "1 BUSD = 50 TWIN",
      cap: "350K",
      acess: "Private",
      participants: "601",
      percentage: "100",
      completion: "350k/350k",
      website:"https://twin.finance/",
      twitterLink:"https://twitter.com/twinfinance",
      telegramLink:"https://t.me/TwinFinance",
      discordLink:"/#",
      ido: "#",
      logo: twinLogo


    },
    {
      id: 3,
      description:
        "Bitcrush Arcade introduces a decentralized live wallet to act as an escrow for players who are comfortable with provably fair games or even slots, but still like to engage seamlessly with Defi protocols.",
      status: "Closed",
      currencyRateType:"BUSD/CRUSH",
      currencyRate: "1 BUSD = 2 CRUSH",
      cap: "450K",
      acess: "Private",
      participants: "1506",
      percentage: "100",
      completion: "391k/450k",
      website:"https://bitcrusharcade.io/",
      twitterLink:"https://twitter.com/bitcrusharcade",
      telegramLink:"https://t.me/BitcrushArcade",
      discordLink:"/#",
      closed:true,
      ido: "#",
      logo: bcaLogo

    },
    
    {
      id: 1,
      description:
        "Decentralize the way your ideas raise capital. Hybrid Auto-yield token Astronaut enables projects to raise capital on a decentralized, permissionless and interoperable environment based on Binance smart chain.",
      status: "Closed",
      currencyRateType:"NAUT/BUSD",
      currencyRate: "1 BUSD = 5 NAUTS",
      cap: "400K",
      acess: "Public",
      participants: "3075",
      percentage: "100",
      completion: "2,000,000",
      website:"https://astronaut.to/",
      twitterLink:"https://twitter.com/astronauttoken",
      telegramLink:"https://t.me/astronaut_bsc",
      discordLink:"https://discord.gg/5DyazH7vPj",
      closed:true,
      ido: "#",
      logo: logo

    },
    // {
    //   id: 2,
    //   description:
    //     "Tapcoins (‘TTT’) are digital tokens built on the Ethereum Blockchain. They are the foundation for blockchain gaming on the Tap Platform. Tapcoins allow gamers to transfer their in-game currency from one game to the next – making it possible to get “paid to play!”",
    //   status: "Open in TBA",
    //   currencyRateType:"BUSD/TTT",
    //   currencyRate: "TBD",
    //   cap: "TBD",
    //   acess: "Private",
    //   participants: "xxxx",
    //   percentage: "100%",
    //   completion: "xxxxx/xxxxx",
    //   website:"https://www.tapproject.net/",
    //   twitterLink:"https://twitter.com/Tap_Coin",
    //   telegramLink:"https://t.me/tapcoin",
    //   discordLink:"https://discord.com/invite/5DyazH7vPj"
    // },
  ];

  return(
    <div>
      <Row>
        <Col xs="12">
          <h2 className="section-title-no-glow text-center">FEATURED POOLS</h2>
        </Col>
      </Row>
      <Row>
        {SampleData.map(data => <PoolCard 

                                  key={data.id} 
                                  {...data} />)}
      </Row>
    </div>
  );
}

export default PoolsPage;
