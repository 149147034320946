import React from "react";
import { Col, Row } from "reactstrap";
import styles from './tokenMetrios.module.scss';

const SampleData = [
  {
    title: "Token Ticker",
    value: "NAUT",
  },
  {
    title: "Token Type",
    value: "BEP-20 (Binance Smart Chain)",
  },
  {
    title: "Initial Total Supply",
    value: "10,000,000",
  },
  {
    title: "Tokens Burned",
    value: "3,000,000",
  },
  {
    title: "New Total Supply",
    value: "7,000,000",
  },
  {
    title: "Current Circulating Supply",
    value: "4,000,000",
  },
  {
    title: "Liquidity",
    value: "2 Years",
  },
  {
    title: "Minting",
    value: "No",
  },
  {
    title: "Proxy",
    value: "No",
  },
  {
    title: "Contract",
    value: "0x05B339B0A346bF01f851ddE47a5d485c34FE220c",
  },
];

const TokenMetrios = () => (
  <Row>
    <Col>
      <div className={styles.card}>
        <h3>TOKEN METRICS</h3>
        {SampleData.map((data) => (
          <div key={Math.random()} className={styles.fact}>
            <Row noGutters form>
              <Col md="4" xs="5" className={styles.label}>{data.title}</Col>
              <Col md="8" xs="7" className={styles.value}>
                {data.value}
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </Col>
  </Row>
);

export default TokenMetrios;
