import React, { useState,useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from './rocketlist.module.scss';
import PoolCardShot from "../../components/poolCard/poolCardShot"; 
import HeronLogo from "../../images/HeronLogo.png";
import LemonLogo from "../../images/LemonLogo.png";
import SayerLogo from "../../images/SayerLogo.png";
import FGSPORTLogo from "../../images/FGSPORT.jpg";
import CakeToolLogo from "../../images/CakeToolLogo.png";
import FANTOMNOMICS from "../../images/FANTOMNOMICS.png";
import TOREKO from "../../images/TOREKO.jpg";
import PS from "../../images/PSLOGO.png";
import EXIP from "../../images/EXIP.png";
import BELLY from "../../images/BELLY.png";
import ARI from "../../images/ARI.png";
import LC from "../../images/LC.png";
import BMW from "../../images/BMW.png";
import DAGO from "../../images/DAGO.gif";
import UNILAB from "../../images/UNILAB.svg";
import JIG from "../../images/JIG.svg";
import PDM from "../../images/PDM.png";
import FADO from "../../images/FADO.png";

import { API_URL } from "../../BlockchainProvider/config";

const ShotList = () => {

  const [] = useState();
  const [SampleData,setSampleData] = useState([]);

  useEffect(() => {
    getdata()
  },[])

  const getdata = async () => {
    let _data = await fetch(API_URL+"/list/bsc") ;
    _data = await _data.json() ; 
    if(_data.data){
      setSampleData(_data.data)      
    }
  }
  return(
    <div className={styles.details}>
    <section>
    <div className={styles.panel}>
      <Row>
        <Col xs="12">
        
        </Col>
      </Row>
      <Row>
        {SampleData.length > 0 && SampleData.map(data => <PoolCardShot 

                                  key={data.id} 
                                  {...data} />)}
      </Row>
      </div>

    </section>
    </div>
  );
}

export default ShotList;
