module.exports={


    //=====TESTNET======
    
    // STAKING_CONTRACT :"0x4c7288EE6257166eF0a2D04cb18dbED2Df259f5b",
    // NAUT_CONTRACT:"0x8a7c3b23e7a1f6f2418649086097b68ed67a41db",
    // ICO_CONTRACT:"0xCa79d5aa5eb2097326EB918FfAa9ceB895c61Dc5",
    // BUSD_CONTRACT:"0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47",
    // NEW_STAKING:"0x2A67A43CE7e5A1F287F08D910bD20f0219edFcdC",
    // NEW_IDO:"0xAE8CAfa79DD1092Bf1c4Be6FE7550209C0F7C1D9",
    // FCFS_CONTRACT_NEW: "0x60F8d30885A4c17599046f66Ec6fdAbC794356a7",


    STAKING_CONTRACT :"0xf0319eD6E918f8167f53Bc12151Dc989025516D0", 
    NAUT_CONTRACT:"0x05b339b0a346bf01f851dde47a5d485c34fe220c",
    BUSD_CONTRACT:"0xe9e7cea3dedca5984780bafc599bd69add087d56",
    NEW_STAKING:"0xb11f4347068eaea624e67c946a611be92d7bf3d2",
    NEW_IDO:"0xfee7c38070bc771a5acd7cf61e33dec1c718e225",
    FCFS_CONTRACT_NEW: "0xAE377F72Eee8d6332B0B2EC319DcAEF942317050",
    WOLF_NEW_IDO:"0xAE377F72Eee8d6332B0B2EC319DcAEF942317050",
    // NEW_IDO_SHOT:"0x28B22d86B0938E5ea24d46EeeE64d2FD2a2AcaFa",
    NEW_IDO_SHOT:"0x8c45bD848AC2bE9df5f42B979CD39Cfb8fe9cdEa",
    API_URL : "https://astronaut-dashboard-api.herokuapp.com/api",

    
    ///////////////////////////////////////////////////////////
    // STAKING_CONTRACT :"0xf0319eD6E918f8167f53Bc12151Dc989025516D0",
    // // NAUT_CONTRACT:"0xf0319ed6e918f8167f53bc12151dc989025516d0",
    // ICO_CONTRACT:"0xaa7e9de8f79f20a49b620ba9f38f541c3da5396e",
    // // BUSD_CONTRACT:"0x8301f2213c0eed49a7e28ae4c3e91722919b8b47",
    // FCFS_CONTRACT:"0x89c8843ac4b6699ce51a06f1883f8a1e93bce6b4",
    ///////////////////////////////////////////////////////////
    LOCKED_DAYS : 1*24*60*60, //days in second



    CHAIN_ID:56 // use 56 for mainnet

}