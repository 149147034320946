import React from "react";
import { Col, Row, Container } from "reactstrap";
import styles from "./welcome.module.scss";

import twitter from "../../images/icons/twitter.png";
import telegram from "../../images/icons/telegram.png";
import discord from "../../images/icons/discord.png";
import binance from "../../images/icons/binance.png";
// import unknown from "../../images/icons/unknown.png";
import unknown from "../../images/icons/globe.svg";
import final from "../../images/Final.png";
import poweredBy from "../../images/typographic.png";

let Particles = [];

for (let index = 0; index < 1000; index++) {
  Particles.push(<div key={index} className="c" />);
}

const Welcome = () => (
  <div className={[styles.welcome]}>
    <section className={styles.section}>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <h1 className="text-center text-lg-left">
              NEXT GENERATION BSC <br /> LAUNCHPAD POWERED BY
            </h1>
            <img alt="Astronaut logo" className={styles.poweredBy} src={poweredBy}/>
            <p className="text-center text-lg-left">
              <span className="highlight">
                Decentralize the way your ideas raise capital.
              </span><br className="d-none d-md-block" />
              Hybrid
              Auto-yield token <span className="highlight">Astronaut</span>{" "}
              enables projects to raise capital{" "} on a decentralized,
              permission-less and interoperable{" "} environment based on the
              Binance smart chain.
            </p>
            <Row className="mb-5">
              <Col className={styles.actions}>
                <a
                  target="_blank"
                  href="https://dex.apeswap.finance/#/swap?outputCurrency=0x05b339b0a346bf01f851dde47a5d485c34fe220c"
                  rel="noreferrer"
                >
                  <button className="button">Buy NAUT</button>
                </a>
                <a
                  target="_blank"
                  href="https://t.me/astronaut_bsc"
                  rel="noreferrer"
                >
                  <button className="button">Telegram</button>
                </a>
              </Col>
            </Row>
            <Row>
              <Col className={styles.social}>
                <a
                  target="_blank"
                  href="https://twitter.com/astronauttoken"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={twitter} alt="twitter" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://t.me/astronaut_bsc"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={telegram} alt="telegram" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://discord.gg/5DyazH7vPj"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={discord} alt="discord" />
                  </button>
                </a>
                <a target="_blank" href="https://bscscan.com/token/0x05b339b0a346bf01f851dde47a5d485c34fe220c" rel="noreferrer">
                  <button className="circle button">
                    <img src={binance} alt="binance" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://astronaut.to/"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={unknown} alt="Tapcoin" />
                  </button>
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <div >
              <div>
                <img
                  alt="Astronaut"
                  id="logo"
                  className={styles.image_style}
                  src={final}
                />
              </div>

            </div>
          </Col>
        </Row>
      </Container>
        <div className={styles.sky}>
          <div className={styles.stars}></div>
          <div className={styles.stars1}></div>
          <div className={styles.stars2}></div>
          <div className={styles.shooting_stars}></div>
        </div>
    </section>
  </div>
);

export default Welcome;
