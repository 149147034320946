import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "reactstrap";

import { FCFS_CONTRACT_NEW, NEW_IDO_SHOT } from "../../BlockchainProvider/config"
import styles from "./depositeScreen.module.scss";
import pnkShdw from "../../images/shadow_pink.png"
import astrorocket from "../../images/astrorocket.png"
import blueArc from "../../images/blue_line.png"
import astro from "../../images/astronaut.png"
import rocket from "../../images/rocket.png"
import bar from "../../images/gradient.png"
import blueSh from "../../images/shadow_blue.png"
import helmet from "../../images/halmet_with_crown.png"
import Web3 from "web3";
import moment from "moment";
import WalletConnect from "../walletConnect/walletConnect";
import shotBanner from "../../images/shotBanner.png" ;
import BUSD_ABI from "../../BlockchainProvider/ABI/NAUT_ABI.json"
import {useWallet} from '@binance-chain/bsc-use-wallet'
import FSFC_ABI from "../../BlockchainProvider/ABI/NEW_IDO_SHOT.json"


const DepositeScreenShot = ({ fcfsContract, busdContract ,onTimeRecevied,claimEnabled , site , description,ido}) => {

    const wallet = useWallet()
  
    const [inputAmount, setAmountInput] = useState();
    const [myMax, setMyMax] = useState();
    const [myMaxLimit, setMyMaxLimit] = useState();
    
    const [myTier, setMyTier] = useState();
    

    const [busdAllowance, setBusdAllowance] = useState(0);
    const [busdSymbol, setBusdSymbol] = useState(0);
    const [userData, setUserData] = useState([]);
    
    const [launchTime, setLaunchTime] = useState("-");
    const [raisedPercent, setRaisedPercent] = useState("-");
    const [icoTarget, setIcoTarget] = useState("-");
    const [receivedAmount, setReceivedAmount] = useState("-");
    const [endTime, setEndTime] = useState("-");

    const [totalSupply, setTotalSupply] = useState("-");
    const [outPutSymbol, setOutPutSymbol] = useState(null);
    
    const [icoStatus, setIcoStatus] = useState(-1)
    const [maxInvestment, setMaxInvestment] = useState("")
    const [yourContribuition, setYourContribuition] = useState("")


    const [claimTokenAmount,setClaimTokenAmount]= useState(0);

    // const [nautlimit,setnautlimit]= useState(0);
    const [vestingPercentage,setVestingPercentage]= useState(0);
    const [vestingTime,setVestingTime]= useState(0);

    


    const [round,setRound]= useState(0);
    const [nautDecimals,setnautDecimals]= useState(0);
    
    const [nautSymbol,setnautsymbol]= useState(0);
    const [nautbalance,setnautbalance]= useState(0);

    const [tokenPrice,setTokenPrice] = useState();

    const [tierOneMax,setTierOneMax] = useState();
    const [tierSecondMax,setTierSecondMax] = useState();
    const [tierThreeMax,setTierThreeMax] = useState();
    const [tierFourMax,setTierFourMax] = useState();


    const [claimable,setClaimable] = useState();
    const [investable,setInvestable] = useState();
    const [investorCount,setInvestorCount] = useState(0);
    const [investorError,setInvestorError] = useState(null);
    const [investorFetched,setInvestorFetched] = useState(0);
    
const [day,setDay] = useState(null);
const [hour,setHour] = useState(null);
const [minute,setMinute] = useState(null);
const [second,setSecond] = useState(null);
const [showTimer,setShowTimer] = useState(false);
    


    const [userInvested,setUserInvested] = useState();


const tierArray = [
    "NA","Tier 1","Tier 2","Tier 3","Tier 4"
];
    

    useEffect(() => {
        // getUserData();
    });

    useEffect(() => {

        if (fcfsContract && wallet.account) {
            initContract()
        }

    }, [fcfsContract,wallet.account])

    useEffect(() => {

        if (busdContract && wallet.account) {
            initBUSDContract()
        }

    }, [busdContract,wallet.account])


    const initContract = async () => {
        let _web3 = new Web3(window.ethereum);

        try{
            let myMaxLt = await fcfsContract.methods.remainingContribution(wallet.account).call();
            setMyMax((myMaxLt / 1e18))
            let myMaxLimit = await fcfsContract.methods.checkMaxInvestment(wallet.account).call();
            setMyMaxLimit((myMaxLimit / 1e18))
            
            let _myTier = await fcfsContract.methods.checkTier(wallet.account).call();
            setMyTier(_myTier)

            let _startTime = await fcfsContract.methods.idoTime().call();
            _startTime = _startTime*1e3 ;
            let _now = new Date().getTime() ; 
            if(_startTime > _now){
                setShowTimer(true);
                setInterval(() => {
                    startTimer(_startTime);
                }, 1000);
            }else{
                setShowTimer(false);
            }

            
            let _tierOneMax = await fcfsContract.methods.Tier1maxInvestment().call();
            let _tierSecondMax = await fcfsContract.methods.Tier2maxInvestment().call();
            let _tierThreeMax = await fcfsContract.methods.Tier3maxInvestment().call();
            let _tierFourMax = await fcfsContract.methods.Tier4maxInvestment().call();
            setTierOneMax(_tierOneMax/1e18);
            setTierSecondMax(_tierSecondMax/1e18);
            setTierThreeMax(_tierThreeMax/1e18);
            setTierFourMax(_tierFourMax/1e18);
        }
        catch {
            console.log("Functions not found")
        }


        let output = await fcfsContract.methods.outputtoken().call();

        const _outputContract = new _web3.eth.Contract(BUSD_ABI, output);
        let _outputSymbol = await _outputContract.methods.symbol().call();
        setOutPutSymbol(_outputSymbol);

        let naut = await fcfsContract.methods.naut().call();
        // let _nautLimit = await fcfsContract.methods.nautlimit().call() ;
        // setnautlimit(_nautLimit) ;
        let _round = await fcfsContract.methods.round().call() ;
        setRound(_round) ;
        
        const _nautContract = new _web3.eth.Contract(BUSD_ABI, naut)
        let _nautSymbol = await _nautContract.methods.symbol().call();
        setnautsymbol(_nautSymbol);
        if(wallet.account){
            let _nautBalance = await _nautContract.methods.balanceOf(wallet.account).call();
            setnautbalance(_nautBalance);
            let _nautDecimals = await _nautContract.methods.decimals().call();
            setnautDecimals(_nautDecimals);
            
        }


        // let _launchTime = await fcfsContract.methods.icoStartTime().call();
        // setLaunchTime(moment(_launchTime * 1000).format("MMM DD YYYY hh:mm:ss a"))


        let _icoTarget = await fcfsContract.methods.icoTarget().call();
        setIcoTarget((_icoTarget / 1e18))




        let _receivedAmount = await fcfsContract.methods.receivedFund().call();
        setReceivedAmount(_receivedAmount / 1e18)


        setRaisedPercent(((_receivedAmount* 100 / _icoTarget) ).toFixed(2))


        let _totalSupply = await fcfsContract.methods.totalsupply().call();
        setTotalSupply((_totalSupply / 1e18).toFixed(2))

        let _vestingPercentage =  await fcfsContract.methods.vestingperc().call();
        setVestingPercentage(_vestingPercentage)

        let _vestingTime =  await fcfsContract.methods.vestingTime().call();
        _vestingTime = new Date(_vestingTime*1e3).toUTCString() ;
        setVestingTime(_vestingTime)
        

        let _claimable = await fcfsContract.methods.claimenabled().call();
        let _investable = await fcfsContract.methods.investingenabled().call();
        
        setClaimable(_claimable);
        setInvestable(_investable);

        if(!_investable && _claimable ){
            onTimeRecevied("Finished")
        }

        // let _endTime = await fcfsContract.methods.icoEndTime().call();
        // initEndTimer(new Date(_endTime*1000))

        let _tokenPrice = await fcfsContract.methods.tokenPrice().call();

        setTokenPrice(_tokenPrice)

        let _userInvested = await fcfsContract.methods.userinvested(wallet.account).call();
        setUserInvested((_userInvested/1e18).toFixed(2))

        let _yourContribution = await fcfsContract.methods.userinvested(wallet.account).call();
        setYourContribuition((_yourContribution/1e18).toFixed(2))

        // let _maxInvestment = await fcfsContract.methods.maxInvestment().call();
        // setMaxInvestment((_maxInvestment/1e18).toFixed(2))

 
        let _yourClaim = await fcfsContract.methods.userremaininigClaim(wallet.account).call();

        // console.log({userInvested,tokenPrice})
        let claimTokenAmount = parseFloat(_yourClaim)/1e18;

        setClaimTokenAmount(claimTokenAmount);



    }

    const initBUSDContract = async () => {

        let allowance = await busdContract.methods.allowance(wallet.account, ido).call();
        setBusdAllowance(allowance)

        let _symbol = await busdContract.methods.symbol().call();
        setBusdSymbol(_symbol)
      

    }


   
const startTimer = (_t) => {
	const countDate = new Date(_t);
	const now = new Date().getTime();
	const gap = countDate - now;
		
	const second = 1000,
	  minute = second * 60,
	  hour = minute * 60,
	  day = hour * 24;
  
	const textDay = Math.floor(gap / day),
	  textHour = Math.floor((gap % day) / hour),
	  textMinute = Math.floor((gap % hour) / minute),
	  textSecond = Math.floor((gap % minute) / second);
  
	setDay(textDay+"d");
	setHour(textHour+"h");
	setMinute(textMinute+"m");
	setSecond(textSecond+"s");
		}

    const renderStakeButtonText = () => {
        if (claimable) {
            return "Withdraw"
        } else if (investable) {
           if (myMax > busdAllowance) {
                return "Approve "+busdSymbol
            } else {
                return "Buy"
            }
        } else {
            return "Loading..."
        }
    }


    const handleSubmitClick = () => {
        if (claimable) {
            handleWithdraw()
        } else if (investable) {
           if (myMax > busdAllowance) {
                handleAllowance()
            } else {
                deposit()
            }
        } else {
        }
    }


    const initEndTimer=(deadline)=>{
        var interval = setInterval(function () {
          var now = new Date().getTime();
          var t = deadline - now;
          var days = Math.floor(t / (1000 * 60 * 60 * 24));
          var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((t % (1000 * 60)) / 1000);
          setEndTime(days + "d "
          + hours + "h " + minutes + "m " + seconds + "s ");
          if (t < 0) {
            clearInterval(interval);
            setEndTime("Expired")
          }
        }, 1000);
      }
    

    const renderPanel = () => {

        if (investable) {
            return <div className={styles.amountfield}>
                <p className={styles.txt}>Your Tokens to stake</p>
                <input type="text" placeholder="000000.0" value={!isNaN(inputAmount)?inputAmount:0} onChange={(i)=>{
                    setAmountInput(i.target.value)
                }} />
                <button className={styles.buttonMax} onClick={() => {
                    setAmountInput(myMax.toFixed(2))
                }}>MAX</button>
                {/* <p className={styles.txt}>You'll be refunded any excess tokens when you claim</p> */}
            </div>
        } else if(claimable){
            return <div className={styles.amountfield}>
            <p className={styles.txt}>Your Tokens to claim</p>
            <input type="text" disabled placeholder="000000.0" value={claimTokenAmount} />
            
            {/* <p className={styles.txt}>You'll be refunded any excess tokens when you claim</p> */}
        </div>
        }

    }

    const handleWithdraw = async () => {
        if(!claimEnabled){
            alert("Claiming is not available")
            return
        }
        await fcfsContract.methods.claimTokens().send({ from: wallet.account }).then((res)=>{

            setClaimTokenAmount(0)
        })

    }
 
    const handleNaut =   () => {
        alert("Please Hold NAUT in order to participate")
        return
    }

    const deposit = async () => {
        console.log("ssdsdsssd",inputAmount)
        if (!inputAmount || isNaN(inputAmount)) {

            alert("Please Enter A Valid Amount")
            return
        }
        let amount = Web3.utils.toWei(inputAmount);

        await fcfsContract.methods.Investing(amount).send({ from: wallet.account }).then((rep)=>{
            setAmountInput(0)
        })
    }

    const getUserData = async () => {
    let _web3 = new Web3(window.ethereum);

        let tempArray = [] ;
         const _fcfsContract = new _web3.eth.Contract(FSFC_ABI, ido)
        let _count = investorCount ; 
        for(let i = 0 ; i < _count ; i++){
            setInvestorError(false);

        let _temp = {} ;
        try{
            let user =   await _fcfsContract.methods.investors(i).call() ; 
            let amount =   await _fcfsContract.methods.userinvested(user).call() ; 
            _temp['address'] = user ;
            _temp['amount'] = amount ;
            tempArray.push(_temp);
            console.log(tempArray);
            setInvestorFetched((i+1));
            if(i == (_count-1)){
                setUserData(tempArray);
            }
        }
        catch {
            setInvestorError(true);
        }
       


        }
        
    }

    const handleAllowance = async () => {
        await busdContract.methods.approve(ido, ("20000000000000000000000000000000").toString()).send({ from: wallet.account }).then(() => {
            deposit()
        })
    }
    const handleCountChange =  (e) => {
        setInvestorCount(e.target.value);
    }

    


    return <section className={styles.section1}>
        <Container>
            <Row className={styles.card1}>
            <img width="100%" src={shotBanner}></img>
           
                {/* <div className={styles.pnkshdw}>
               
                    <img src={pnkShdw}></img>
                </div>
            
                <div className={styles.logo}>
                    <img src={astrorocket}></img>
                    <p>Second round for our Astronaut IDO’s available to all NAUT holders without the need for
                      staking. (Max spend limits apply)</p>
                </div>
                <div className={styles.blueArc}>
                    <img src={blueArc}></img>
                </div>
                <div className={styles.astronaut}>
                    <img src={astro}></img>
                </div>
                <div className={styles.rocket}>
                    <img src={rocket}></img>
                </div> */}
            </Row>
            <Row className={styles.card2}>
                <Row style={{width:raisedPercent+"%"}}>
                    <Col lg="12">
                        {/* <img src={bar} className={styles.barImg}></img> */}
                        <div className="progress" style={{
                            background: `linear-gradient(to right, #df05ff 0%, #0968b8 100%)`,
                            width:"100%",
                            margin: "30px 0 0 0", borderRadius: "20px"
                        }}>
                            <span style={{ margin: "8px 0px 0 15px", fontSize: "10px" }}>Progress ({receivedAmount}/{icoTarget})</span>
                            <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>



                        <span>Progress {raisedPercent}%</span>


                       
                    </Col>
                </Row>
                <Row >
                    <Col lg="6">
                    {renderPanel()}
                        <div className={styles.unlckBtn}>
                            <button className="button" onClick={() => {
                                handleSubmitClick()
                            }}>{renderStakeButtonText()}</button>
                        </div>
                        <div className={styles.leftCol}>
                                <p className={styles.leftTxtBig}>Deposited ({busdSymbol})</p>
                                <p className={styles.rytTxtBig}>${yourContribuition}</p>
                                {
                                        investable && round == 0 ?
                                         <button
                                         style={{
                                         width:"auto"}}
                                         class={styles.timer} >
                                        Whitelist Round Ongoing</button>
                                        :
                                        investable &&  round == 1 ? 
                                         <button
                                         style={{
                                         width:"auto"}}
                                         class={styles.timer} >
                                        Astroshot Ongoing</button>
                                         :
                                         investable ?
                                         <button
                                         style={{
                                         width:"auto"}}
                                         class={styles.timer} >
                                          FCFS OPEN</button>
                                          :
                                          <span></span>
                                     
                                }

                                {
                                    showTimer &&
                                    <button
                                    style={{
                                    width:"auto"}}
                                    class={styles.timer} >
                                   Starting in {day}: {hour} : {minute} : {second}</button>

                                }
                             
                            </div>
                    </Col>
                    <Col lg="6">
                        <Row>
                            {/* <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Launch Time</p>
                                <p className={styles.rytTxt}>{launchTime}</p>
                            </div> */}

                            {/* <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Ends In</p>
                                <p className={styles.rytTxt}>{endTime}</p>
                            </div> */}
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>For Sale ({outPutSymbol})</p>
                                <p className={styles.rytTxt}>{totalSupply}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>To raise ({busdSymbol})</p>
                                <p className={styles.rytTxt}>{icoTarget}</p>
                            </div>

                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Your Tier</p>
                                <p className={styles.rytTxt}>{tierArray[myTier]}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Your Max Invesment ({busdSymbol})</p>
                                <p className={styles.rytTxt}>{myMaxLimit}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Your Remaining Invesment ({busdSymbol})</p>
                                <p className={styles.rytTxt}>{myMax}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Tier 1 Max Invesment ({busdSymbol})</p>
                                <p className={styles.rytTxt}>{tierOneMax}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Tier 2 Max Invesment ({busdSymbol})</p>
                                <p className={styles.rytTxt}>{tierSecondMax}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Tier 3 Max Invesment ({busdSymbol})</p>
                                <p className={styles.rytTxt}>{tierThreeMax}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Tier 4 Max Invesment ({busdSymbol})</p>
                                <p className={styles.rytTxt}>{tierFourMax}</p>
                            </div>
                            {/* <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Naut Requirement ({nautSymbol})</p>
                                <p className={styles.rytTxt}>{parseFloat(nautlimit/1e1 ** nautDecimals).toFixed()}</p>
                            </div> */}

                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Your Contribution ({busdSymbol})</p>
                                <p className={styles.rytTxt}>${yourContribuition}</p>
                            </div>


                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Total raised (% of target)</p>
                                <p className={styles.rytTxt}>{raisedPercent}%</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Vesting Percentage</p>
                                <p className={styles.rytTxt}>{parseFloat(vestingPercentage).toFixed()}%</p>
                            </div>    
                            
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Vesting Time</p>
                                <p className={styles.rytTxt}>{vestingTime}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <button className={styles.ViewBtn} onClick={()=>{
                                    
                                    window.open(site, '_blank').focus();

                                }}>View Project Site</button>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.introTxt}>{description}</p>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Row>


        </Container>
        {
            wallet.account == "0x056684669C0D5148E4D24E538dD01966637622B2" && 
       <section className="p-5"> 
           <input type="text" onChange={handleCountChange} placeholder="Enter Number to fetch " /> <button style={{cursor: "pointer"}} onClick={getUserData} >Fetch List </button>
           {
               investorError &&
               <p className="mt-2">Investor Count Invalid. Try lower number</p>
           }
           {
               investorError != null &&
               <p className="mt-2">Fetched User Count: {investorFetched}/{investorCount}</p>
           }
           {
               userData.length > 0 &&
            <table className="table text-white mt-3">
                <thead>
                    <th>address</th>
                    <th>amount</th>
                </thead>
                <tbody>
                   
                    {
                        userData.length > 0 && userData.map((v,i) => {
                            return(
                                <tr>
                                <td>{v.address}</td>
                                <td>{v.amount}</td>
                            </tr>
                            )
                        })
                    }
                </tbody>
            </table>
           }

        </section>
}
    
        <section className={styles.section2}>
            <Container>
                <Row className={styles.card3}>
                    <div className={styles.blueShd}>
                        <img src={blueSh}></img>
                    </div>
                    <div className={styles.helmet}>
                        <img src={helmet}></img>
                    </div>
                    <div className={styles.pnkShd}>
                        <img src={pnkShdw}></img>
                    </div>
                </Row>
                <Row className={styles.card4}>
                    <Col lg="6">
                        <h2>How to take part</h2>
                        <span>Before Sale</span>
                        <p>Get {busdSymbol} tokens</p>
                        <span>During Sale:</span>
                        <p>We will have a countdown timer which will open the pool, you will then commit your {busdSymbol}
tokens to buy into the IAO’s</p>
                        <span>After Sale:</span>
                        <p>Claim the tokens you purchased. Add the contract address to your wallet to insure you have received the tokens</p>
                        <p>Done!</p>
                        <div className={styles.unlckBtn}>
                        <a href="https://astronautfinance.medium.com/how-does-astronaut-setup-idos-d12a4922015b"  target="_blank">
                        <button className="button">Read More</button>
                        </a>
                           
                        </div>
                    </Col>
                    <Col lg="6">
                    <h2>Want to launch your own IAO?</h2>
            <p>Launch your project with Astronaut, Binance Smart Chains most-loved launchpad, to bring your token directly to the most active and and growing community on BSC</p>
            <div className={styles.unlckBtn}>
            <a href="https://docs.google.com/forms/u/1/d/e/1FAIpQLScaMHYQrnfEU4pdpxqU2BcG6-DpsBTgayI9cU-Tg_pJRqaCIA/viewform"  target="_blank">
            <button className="button">Apply To Launch</button>
            </a>
           
            </div>
                    </Col>
                </Row>
            </Container>
        </section >
                
    
 
    </section>
}

export default DepositeScreenShot;