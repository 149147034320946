import React, { useState,useEffect } from "react";
import { Row, Container } from "reactstrap";
import {useWallet} from '@binance-chain/bsc-use-wallet'
import {useParams } from 'react-router-dom';


import Navigation from "../../components/depositeNavigation/navigation";
import DepositeScreenShot from "../depositeScreen/depositeScreenShot";
import WithdrawScreen from "../WithdrawScreen/WithdrawScreen";
import styles from "./depositeWithdraw.module.scss";
import config, { API_URL , BUSD_CONTRACT } from "../../BlockchainProvider/config";
import FSFC_ABI from "../../BlockchainProvider/ABI/NEW_IDO_SHOT.json"
import BUSD_ABI from "../../BlockchainProvider/ABI/NAUT_ABI.json"

import Web3 from "web3"




const DepositeWithdrawShot = () => {
  const [remainingTime, setRemainingTime] = useState();
  const { ido } = useParams();
  const [selectedPage, setSelectedPage] = useState("Deposit");
  const [ web3, setWeb3] = useState()
  const [ account, setAccount] = useState()
  const [fcfsContract,setFcfsContract] = useState();
  const [busdContract,setBUSDContract] = useState();
  const [claimEnabled,setClaimEnabled] = useState(false);
  const [investEnabled,setInvestEnabled] = useState(false);
  const [icoTarget,setIcoTarget] = useState(0);
  const [idoData,setIdoData] = useState({});
  

  const wallet = useWallet();

  const initContracts = async() => {

    let _getIdo  = await fetch(API_URL+"/get/"+ido)
    _getIdo = await _getIdo.json() ; 
    console.log(_getIdo)
    _getIdo = _getIdo.data ; 

    setIdoData(_getIdo);
    let _web3 = new Web3(window.ethereum);
    const _fcfsContract = new _web3.eth.Contract(FSFC_ABI, _getIdo.ido)

    let _inputtoken = await _fcfsContract.methods.inputtoken().call() ;

    

    const _busdContract = new _web3.eth.Contract(BUSD_ABI, _inputtoken)


    let _claimEnabled  = await _fcfsContract.methods.claimenabled().call();
    setClaimEnabled(_claimEnabled)

    let _investEnabled  = await _fcfsContract.methods.investingenabled().call();
    setInvestEnabled(_investEnabled)


    let _icotarget  = await _fcfsContract.methods.icoTarget().call();
    setIcoTarget(_icotarget)
    



    setWeb3(_web3)
    // alert(_busdContract);

    setFcfsContract(_fcfsContract);
    setBUSDContract(_busdContract);

  }

  useEffect(() => {
    if (wallet.status === 'connected') {
      setAccount(wallet.account)
    }
    initContracts()

  }, [wallet.account])

  
 

  const initTimer=(deadline)=>{
    var interval = setInterval(function () {
      var now = new Date().getTime();
      var t = deadline - now;
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((t % (1000 * 60)) / 1000);
      setRemainingTime("Starting in "+days + "d "
      + hours + "h " + minutes + "m " + seconds + "s ");
      if (t < 0) {
        clearInterval(interval);
        setRemainingTime("Started")

      }
    }, 1000);
  }


  return (
    <div className={styles.details}>
      <section className={styles.pb}>
       <Container>
       <Row style={{}}>
          <div style={{marginLeft: 10}}>
            <h5>FCFS Round: First come first serve</h5>
            <span>Final round if Astroshot is not filled</span>

          </div>

          <div>
          {icoTarget == 0 ?  <button
          style={{marginLeft:40,
          width:"100%"}}
          class={styles.timer} >
           <span style={{fontSize:25,fontWeight:"bold",marginLeft:10,marginRight:10}}>
           Starting Soon
           </span>
          </button>:
          investEnabled ?
          <button
          style={{marginLeft:40,
          width:"100%"}}
          class={styles.timer} >
           <span style={{fontSize:25,fontWeight:"bold",marginLeft:10,marginRight:10}}>
           Started
           </span>
          </button>:
          claimEnabled ?
          <button
          style={{marginLeft:40,
          width:"100%"}}
          class={styles.timer} >
           <span style={{fontSize:25,fontWeight:"bold",marginLeft:10,marginRight:10}}>
           Claim
           </span>
          </button>:
          !claimEnabled && !investEnabled ?
          <button
          style={{marginLeft:40,
          width:"100%"}}
          class={styles.timer} >
           <span style={{fontSize:25,fontWeight:"bold",marginLeft:10,marginRight:10}}>
           Paused
           </span>
          </button>:
          null}
            
          </div>
    
        </Row>
       </Container>
        {/* <div >{selectedPage && Pages[selectedPage]}</div> */}
        {/* {renderPages()} */}
        {
  idoData.ido &&

       <DepositeScreenShot
        fcfsContract={fcfsContract}
        busdContract={busdContract}
        account={account}
        claimEnabled = {claimEnabled}
        ido = {idoData.ido}
        site = {idoData.website}
        description = {idoData.description}

        onTimeRecevied={(t)=>{
         
          console.log("tiime",claimEnabled)

          if(claimEnabled ==true){
            setRemainingTime("Claim Tokens")
          }else if(t === "Finished"){
            setRemainingTime("Finished")

          }else{
            initTimer(t)

          }
        }}
      
    //  onExpireTime = {(time)=>{
    //   setExpireTime(time)
    //  }}
  
    //  onIcoOver={(bool)=>{
    //    setIsICOOver(bool)
    //  }}
    //   account={walletAddress && walletAddress}
    //   stakingContract={stakingContract && stakingContract}
    //   nautContract={nautContract && nautContract}
    /> 
      }
      </section>
    </div>
  );
};


export default DepositeWithdrawShot;
