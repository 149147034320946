import { Row, Col, Container,Button,ModalHeader,ModalFooter, Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { withStorage } from 'react-browser-storage';

import Logo from "../../images/gradient-logo.png";
import styles from "./header.module.scss";
import close from "../../images/icons/close.svg";
import downarrow from "../../images/icons/downarrow.svg";
import binanceChain from "../../images/icons/binanceChain.png";
import coin98 from "../../images/icons/coin98.png";
import mathwallet from "../../images/icons/mathwallet.png";
import metamask from "../../images/icons/metamask.png";
import safepal from "../../images/icons/safepal.png";
import tokenpocket from "../../images/icons/tokenpocket.png";
import Trustwallet from "../../images/icons/Trustwallet.png";
import walletConnect from "../../images/icons/walletConnect.png";
import CountDown from "../ComingSoon/";
import {useWallet} from '@binance-chain/bsc-use-wallet'
import bsc from "../../images/bsc.png";
import bsc2 from "../../images/bsc2.png";
import bsc1 from "../../images/bsc1.png";
// import { useWallet } from 'use-wallet'

const Header = ({ provider,network }) => { 
  const [showOption, setOption]= useState(false)
  const [showMenu, setShowMenu] = useState(false);
  const [showSubmenu, setSubmenu]= useState(false)
  const wallet = useWallet()
  const [connectModal, setconnectModal] = useState(null);

  const [disconnectModal, setdisconnectModal] = useState(false);
  const [networkModal, setnetworkModal] = useState(false);
 
  
  const connectModalToggle = () => setconnectModal(!connectModal);
  const disconnectModalToggle = () => setdisconnectModal(!disconnectModal);
  const networkModalToggle = () => setnetworkModal(!networkModal);

  
  const [ providerId, setProvider, clearProvider ] = provider;
  const [ networkId, setNetwork, clearNetwork ] = network;
  console.log(wallet.error);
  const isWalletConnected=()=>{
    return wallet.status === "connected" 
  }
  const connectWallet = (_p) => {
    if(_p == 'injected'){
      wallet.connect();
    }
    else{
      wallet.connect(_p);
    }
    setProvider(_p);
    setconnectModal(!connectModal)
  } 

  const moveNetwork = (n) => {
    setNetwork(n);
    if(n=="polygon"){
      window.location.replace("https://polygon.astronaut.to/")
    }
    else if(n=="fantom"){
      window.location.replace("https://fantom.astronaut.to/")
    }
    networkModalToggle();
  }
  const logout = () => {
    wallet.reset() ;
    setProvider(null);
    setdisconnectModal(!disconnectModal)
 
  } 
    
  const getTrimmedWallet = () => {
    let _walletAddress = wallet.account;
    console.log("walletAddress", _walletAddress)

    if(_walletAddress){
    // setWalletAddress(wallet.account)
    _walletAddress = _walletAddress.substring(0, 6)+"...."+_walletAddress.substring(_walletAddress.length - 6)
    }
    return _walletAddress

  }
 


  useEffect(()=>{    
    
      wallet.connect(providerId);
    console.log("netowkr" , networkId);
      if(networkId == null){
        networkModalToggle()
      }
  
  },[])
  return (
    <Container fluid>
      <Row>
        <Col className={styles.header}>
          <Row className={styles.container}>
            <Col md={12} lg={6} xs={12} className={styles.actions}>
              <a
                className="d-none d-lg-inline"
                rel="noreferrer"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLScaMHYQrnfEU4pdpxqU2BcG6-DpsBTgayI9cU-Tg_pJRqaCIA/viewform?usp=send_form"
              >
                <button className="button">Apply for IDO</button>
              </a>
              <a target="_blank" className="d-none d-lg-inline" href="/pool">
                <button className="button">Launch App</button>
              </a>

              <a className="d-none d-lg-inline" >
                <button className="button" onClick={() => {
                 if(isWalletConnected()){
                  disconnectModalToggle()
                }
                else if(!isWalletConnected()){
                    // wallet.connect('bsc')
                    connectModalToggle()
                  }
               
                
                }}> {isWalletConnected()?
                  getTrimmedWallet() :
                  "Connect Wallet"}</button>
              </a>
              

            </Col>
            <Col md xs={12}>
              <a href="/"><img className={styles.logo} src={Logo} alt="" width="250px" /></a>
            </Col>
            <Col lg={4} md={12} xs={12}>
            <a href="#" className="bsc-wrp">
              <div class="dropdown">
            <button class="btn button dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src={bsc} className="bsc" /> BSC
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#"><img src={bsc} className="bsc" /> BSC</a>
              <a class="dropdown-item" href="https://polygon.astronaut.to/"><img src={bsc2} className="bsc" /> Polygon</a>
              <a class="dropdown-item" href="https://fantom.astronaut.to/"><img src={bsc1} className="bsc" /> Fantom</a>
            </div>
          </div>
              </a>
              <button
                type="button"
                className={`${styles.burger} ${styles.transparent}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </Col>
          </Row>

          <div className={`${styles.menu} ${showMenu && styles.open}`}>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className={`${styles.transparent} ${styles.close}`}
                onClick={() => setShowMenu(false)}
              >
                <img src={close} alt="close" />
              </button>
            </div>
            <ul>

            <li>
              <a className=" d-lg-inline" >
                <button className="button" onClick={() => {
                  if(isWalletConnected()){
                    disconnectModalToggle()
                  }
                  else if(!isWalletConnected()){
                    connectModalToggle()

                  }
               
                
                }}> {isWalletConnected()?
                  getTrimmedWallet(true) :
                  "Connect Wallet"}</button>
              </a>

              </li>
              {/* <li>
                <a href="/pool">IDO Pool</a>
              </li>
              <li>
                <a href="/staking">Staking</a>
              </li> */}
               <li>
              
              {/* <a onClick={()=>setSubmenu(!showSubmenu)}>IAO <i><img src={downarrow} className={styles.mg} alt="close" /></i></a> */}
              {/* {showSubmenu &&
                <div className={showSubmenu ? `${styles.mgUnClick} ${styles.wp}` : `${styles.mgClick} ${styles.wp}`}>
                 
                </div>
              } */}
              

              {/* <li><a href="/staking">Tier Staking</a></li> */}
              <li>
                <a href="/astroshot">Astroshot</a>
              </li>
              <li><a href="/depositeWithdraw">Astrorocket</a></li>
              <li><a href="/pool">IDO Pool</a></li>
              <li><a   target="_blank" href="https://astropool.astronaut.to/">Astropools</a></li>
              
                  
            </li>
              
              
              <li>
                {/* <a data-toggle="collapse" data-target="#demo">Whitepaper</a> */}
                <a onClick={()=>setOption(!showOption)}>Whitepaper <i><img src={downarrow} className={styles.mg} alt="close" /></i></a>
                {showOption &&
                  <div className={showOption ? `${styles.mgUnClick} ${styles.wp}` : `${styles.mgClick} ${styles.wp}`}>
                    <div><a href="https://astronaut-1.gitbook.io/astronaut/" target="_blank">English</a></div>
                    <div><a href="https://astronaut-1.gitbook.io/astronaut-zh-cn/" target="_blank">简体中文</a></div>
                  </div>
                }
                {/* <div id="demo" class="collapse">
                  <div><a href="https://astronaut-1.gitbook.io/astronaut/" target="_blank">English</a></div>
                  <div><a href="https://astronaut-1.gitbook.io/astronaut/" target="_blank">chines</a></div>
                </div> */}
              </li>
{/*              
              <li>
                <a href="/#market">Market Makers</a>
              </li>
              <li>
                <a href="/#faq">FAQ</a>
              </li> */}
           
             

          
              <li>
                <a
                  className="d-lg-none"
                  rel="noreferrer"
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScaMHYQrnfEU4pdpxqU2BcG6-DpsBTgayI9cU-Tg_pJRqaCIA/viewform?usp=send_form"
                >
                  <button className="button">Apply for IDO</button>
                </a>

              </li>
              <li>
              <a href="#" className="bsc-wrp-mobile">
              <div class="dropdown">
            <button class="btn button dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Choose Network
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#"><img src={bsc} className="bsc" /> BscScan</a>
              <a class="dropdown-item" href="https://polygon.astronaut.to/"><img src={bsc2} className="bsc" /> Polygon</a>
              <a class="dropdown-item" href="https://fantom.astronaut.to/"><img src={bsc1} className="bsc" /> Fantom</a>
            </div>
          </div>
              </a>
              </li>
              <li>
                <a
                    className="d-lg-none"
                    rel="noreferrer"
                    target="_blank"
                    href="/pool"
                >
                  <button className="button">Launch App</button>
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>

      
      
            
 <Modal isOpen={networkModal} toggle={networkModalToggle} className="disconnect-box"  centered={true}>
 <ModalHeader toggle={networkModalToggle}><span className="ml-4 roititle">Choose Network</span></ModalHeader>
   <ModalBody>
   <div className="mt-3 roiarea2">
           

   <div className=" mb-3 connectbtns" onClick={() => moveNetwork('bsc')}><img className="" src={bsc} /><p>Binance </p>  </div>                 
                 <div className="connectbtns mb-3" onClick={() => moveNetwork('polygon')}><img className="" src={bsc2} /><p>Polygon</p>  </div>                 
                 <div className="connectbtns mb-3" onClick={() => moveNetwork('fantom')}><img className="" src={bsc1} /><p>Fantom</p>  </div>                 
                

       </div>        
   </ModalBody>
    
 </Modal>

 <Modal isOpen={disconnectModal} toggle={disconnectModalToggle} className="disconnect-box"  centered={true}>
 <ModalHeader toggle={disconnectModalToggle}><span className="ml-4 roititle">Your Wallet</span></ModalHeader>
   <ModalBody>
   <div className="mt-3 roiarea">
           

                <p className="walletText">{wallet.account}</p>
                <p className="mt-2 mb-3 text-center">
                  <a href={"https://bscscan.com/address/"+wallet.account} target="_blank">View on BscScan? <i class="fa fa-external-link" aria-hidden="true"></i></a>
                  </p>
                 <button className="connectbtn mb-3" onClick={() => logout()}>Logout</button>                 

       </div>        
   </ModalBody>
    
 </Modal>

 <Modal isOpen={connectModal} toggle={connectModalToggle} className="connect-box"  centered={true}>
 <ModalHeader toggle={connectModalToggle}><span className="ml-4 roititle">Connect Wallet</span></ModalHeader>
   <ModalBody>
   <div className="mt-3 roiarea">
           
                 <div className="connectbtn mb-3" onClick={() => connectWallet('injected')}><span className="float-left">MetaMask</span> <img className="float-right" src={metamask} /> </div>                 
                 <div className="connectbtn mb-3" onClick={() => connectWallet('injected')}><span className="float-left">TrustWallet</span> <img className="float-right" src={Trustwallet} /> </div>                 
                 <div className="connectbtn mb-3" onClick={() => connectWallet('injected')}><span className="float-left">MathWallet</span> <img className="float-right" src={mathwallet} /> </div>                 
                 <div className="connectbtn mb-3" onClick={() => connectWallet('injected')}><span className="float-left">TokenPocket</span> <img className="float-right" src={tokenpocket} /> </div>                 
                 <div className="connectbtn mb-3" onClick={() => connectWallet('walletconnect')}><span className="float-left">Wallet Connect</span> <img className="float-right" src={walletConnect} /> </div>                 
                 <div className="connectbtn mb-3" onClick={() => connectWallet('bsc')}><span className="float-left">Binance Smart Chain Wallet</span> <img className="float-right" src={binanceChain} /> </div>                 
                 <div className="connectbtn mb-3" onClick={() => connectWallet('injected')}><span className="float-left">Safepal Wallet</span> <img className="float-right" src={safepal} /> </div>                 
                 <div className="connectbtn mb-3" onClick={() => connectWallet('injected')}><span className="float-left">Coin98 Wallet</span> <img className="float-right" src={coin98} /> </div>                 
                                              
                <p className="bottomtext"><a href="https://docs.pancakeswap.finance/get-started/connection-guide" target="_blank"><i class="fa fa-question-circle-o" aria-hidden="true"></i> Learn how to connect?   </a></p>
       </div>        
   </ModalBody>
    
 </Modal>


    </Container>
  );
};

export default withStorage(['provider','network'])(Header);
