import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "reactstrap";

import { FCFS_CONTRACT_NEW } from "../../BlockchainProvider/config"
import styles from "./depositeScreen.module.scss";
import pnkShdw from "../../images/shadow_pink.png"
import astrorocket from "../../images/astrorocket.png"
import blueArc from "../../images/blue_line.png"
import astro from "../../images/astronaut.png"
import rocket from "../../images/rocket.png"
import bar from "../../images/gradient.png"
import blueSh from "../../images/shadow_blue.png"
import helmet from "../../images/halmet_with_crown.png"
import Web3 from "web3";
import moment from "moment";
const DepositeScreen = ({ fcfsContract, account, busdContract ,onTimeRecevied,claimEnabled}) => {


    const [inputAmount, setAmountInput] = useState();
    const [myMax, setMyMax] = useState();
    const [busdAllowance, setBusdAllowance] = useState(0);
    const [launchTime, setLaunchTime] = useState("-");
    const [raisedPercent, setRaisedPercent] = useState("-");
    const [icoTarget, setIcoTarget] = useState("-");
    const [receivedAmount, setReceivedAmount] = useState("-");
    const [endTime, setEndTime] = useState("-");

    const [totalSupply, setTotalSupply] = useState("-");

    const [icoStatus, setIcoStatus] = useState(-1)
    const [maxInvestment, setMaxInvestment] = useState("")
    const [yourContribuition, setYourContribuition] = useState("")


    const [claimTokenAmount,setClaimTokenAmount]= useState(0)

    const [tokenPrice,setTokenPrice] = useState();
    const [userInvested,setUserInvested] = useState();

    useEffect(() => {

        if (fcfsContract) {
            initContract()
        }

    }, [fcfsContract])

    useEffect(() => {

        if (busdContract) {
            initBUSDContract()
        }

    }, [busdContract])


    const initContract = async () => {

        let myMaxLimit = await fcfsContract.methods.maxInvestment().call();
        setMyMax((myMaxLimit / 1e18))



        let _launchTime = await fcfsContract.methods.icoStartTime().call();
        setLaunchTime(moment(_launchTime * 1000).format("MMM DD YYYY hh:mm:ss a"))


        let _icoTarget = await fcfsContract.methods.icoTarget().call();
        setIcoTarget((_icoTarget / 1e18))




        let _receivedAmount = await fcfsContract.methods.receivedFund().call();
        setReceivedAmount(_receivedAmount / 1e18)


        setRaisedPercent(((_receivedAmount* 100 / _icoTarget) ).toFixed(2))


        let _totalSupply = await fcfsContract.methods.totalsupply().call();
        setTotalSupply((_totalSupply / 1e18).toFixed(2))



        let _icoStatus = await fcfsContract.methods.getIcoStatus().call();

        setIcoStatus(_icoStatus)

        if(_icoStatus == 3){
            onTimeRecevied("Finished")
        }else{
            onTimeRecevied(new Date(_launchTime*1000))
        }       
        



        let _endTime = await fcfsContract.methods.icoEndTime().call();
        initEndTimer(new Date(_endTime*1000))



        let _tokenPrice = await fcfsContract.methods.tokenPrice().call();

        setTokenPrice(_tokenPrice)

        let _userInvested = await fcfsContract.methods.userinvested(account).call();
        setUserInvested((_userInvested/1e18).toFixed(2))




        let _yourContribution = await fcfsContract.methods.userinvested(account).call();
        setYourContribuition((_yourContribution/1e18).toFixed(2))







        let _maxInvestment = await fcfsContract.methods.maxInvestment().call();
        setMaxInvestment((_maxInvestment/1e18).toFixed(2))




        console.log({userInvested,tokenPrice})
        let claimTokenAmount = parseFloat(_userInvested)*parseFloat(_tokenPrice)/1e18;

        setClaimTokenAmount(claimTokenAmount);



    }

    const initBUSDContract = async () => {

        let allowance = await busdContract.methods.allowance(account, FCFS_CONTRACT_NEW).call();
        setBusdAllowance(allowance)
      

    }


   
    const renderStakeButtonText = () => {


        if (icoStatus == 3) {
            return "Withdraw"
        } else if (icoStatus == 2) {
            return "ICO Paused"

        } else if (icoStatus == 1) {
            if (myMax > busdAllowance) {
                return "Approve BUSD"
            } else {
                return "Buy"
            }
        } else {
            return "Loading..."
        }




    }


    const handleSubmitClick = () => {


        if (icoStatus == 3) {
            handleWithdraw()
        } else if (icoStatus == 1) {
            if (myMax > busdAllowance) {
                handleAllowance()
            } else {
                deposit()
            }
        } else {
        }

    }


    const initEndTimer=(deadline)=>{
        var interval = setInterval(function () {
          var now = new Date().getTime();
          var t = deadline - now;
          var days = Math.floor(t / (1000 * 60 * 60 * 24));
          var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((t % (1000 * 60)) / 1000);
          setEndTime(days + "d "
          + hours + "h " + minutes + "m " + seconds + "s ");
          if (t < 0) {
            clearInterval(interval);
            setEndTime("Expired")
          }
        }, 1000);
      }
    

    const renderPanel = () => {

        if (icoStatus == 1) {
            return <div className={styles.amountfield}>
                <p className={styles.txt}>Your Tokens to stake</p>
                <input type="text" placeholder="000000.0" value={!isNaN(inputAmount)?inputAmount:0} onChange={(i)=>{
                    setAmountInput(i.target.value)
                }} />
                <button className={styles.buttonMax} onClick={() => {
                    setAmountInput(myMax.toFixed(2))
                }}>MAX</button>
                {/* <p className={styles.txt}>You'll be refunded any excess tokens when you claim</p> */}
            </div>
        } else if(icoStatus == 3){
            return <div className={styles.amountfield}>
            <p className={styles.txt}>Your Tokens to claim</p>
            <input type="text" disabled placeholder="000000.0" value={claimTokenAmount} />
            
            {/* <p className={styles.txt}>You'll be refunded any excess tokens when you claim</p> */}
        </div>
        }

    }

    const handleWithdraw = async () => {
        if(!claimEnabled){
            alert("Claiming is not available")
            return
        }
        await fcfsContract.methods.claimTokens().send({ from: account }).then((res)=>{

            setClaimTokenAmount(0)
        })

    }

    const deposit = async () => {
        console.log("ssdsdsssd",inputAmount)
        if (!inputAmount || isNaN(inputAmount)) {

            alert("Please Enter A Valid Amount")
            return
        }
        let amount = Web3.utils.toWei(inputAmount);

        await fcfsContract.methods.Investing(amount).send({ from: account }).then((rep)=>{
            setAmountInput(0)
        })
    }

    const handleAllowance = async () => {
        await busdContract.methods.approve(FCFS_CONTRACT_NEW, ("20000000000000000000000000000000").toString()).send({ from: account }).then(() => {
            deposit()
        })
    }


    return <section className={styles.section1}>
        <Container>
            <Row className={styles.card1}>
           
                <div className={styles.pnkshdw}>
               
                    <img src={pnkShdw}></img>
                </div>
            
                <div className={styles.logo}>
                    <img src={astrorocket}></img>
                    <p>Second round for our Astronaut IDO’s available to all NAUT holders without the need for
                      staking. (Max spend limits apply)</p>
                </div>
                <div className={styles.blueArc}>
                    <img src={blueArc}></img>
                </div>
                <div className={styles.astronaut}>
                    <img src={astro}></img>
                </div>
                <div className={styles.rocket}>
                    <img src={rocket}></img>
                </div>
            </Row>
            <Row className={styles.card2}>
                <Row style={{width:raisedPercent+"%"}}>
                    <Col lg="12">
                        {/* <img src={bar} className={styles.barImg}></img> */}
                        <div className="progress" style={{
                            background: `linear-gradient(to right, #df05ff 0%, #0968b8 100%)`,
                            width:"100%",
                            margin: "30px 0 0 0", borderRadius: "20px"
                        }}>
                            <span style={{ margin: "8px 0px 0 15px", fontSize: "10px" }}>Progress ({receivedAmount}/{icoTarget})</span>
                            <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>



                        <span>Progress {raisedPercent}%</span>


                       
                    </Col>
                </Row>
                <Row >
                    <Col lg="6">
                    {renderPanel()}
                        <div className={styles.unlckBtn}>
                            <button className="button" onClick={() => {
                                handleSubmitClick()
                            }}>{renderStakeButtonText()}</button>
                        </div>
                        <div className={styles.leftCol}>
                                <p className={styles.leftTxtBig}>Deposited (BUSD)</p>
                                <p className={styles.rytTxtBig}>${yourContribuition}</p>
                            </div>
                    </Col>
                    <Col lg="6">
                        <Row>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Launch Time</p>
                                <p className={styles.rytTxt}>{launchTime}</p>
                            </div>

                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Ends In</p>
                                <p className={styles.rytTxt}>{endTime}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>For Sale (HPAD)</p>
                                <p className={styles.rytTxt}>{totalSupply}</p>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>To raise (USD)</p>
                                <p className={styles.rytTxt}>${icoTarget}</p>
                            </div>

                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Max Invesment (BUSD)</p>
                                <p className={styles.rytTxt}>${maxInvestment}</p>
                            </div>


                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Your Contribution (BUSD)</p>
                                <p className={styles.rytTxt}>${yourContribuition}</p>
                            </div>


                            <div className={styles.rytCol}>
                                <p className={styles.leftTxt}>Total raised (% of target)</p>
                                <p className={styles.rytTxt}>{raisedPercent}%</p>
                            </div>
                            <div className={styles.rytCol}>
                                <button className={styles.ViewBtn} onClick={()=>{
                                    
                                    window.open("https://closedsea.com/", '_blank').focus();

                                }}>View Project Site</button>
                            </div>
                            <div className={styles.rytCol}>
                                <p className={styles.introTxt}>Closedsea is designed to offer a multi-chain experience across all blockchains that support smart contracts such as; BSC, POLYGON, ETH, SOL, and more.
</p>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Row>


        </Container>

        <section className={styles.section2}>
            <Container>
                <Row className={styles.card3}>
                    <div className={styles.blueShd}>
                        <img src={blueSh}></img>
                    </div>
                    <div className={styles.helmet}>
                        <img src={helmet}></img>
                    </div>
                    <div className={styles.pnkShd}>
                        <img src={pnkShdw}></img>
                    </div>
                </Row>
                <Row className={styles.card4}>
                    <Col lg="6">
                        <h2>How to take part</h2>
                        <span>Before Sale</span>
                        <p>Get BUSD tokens</p>
                        <span>During Sale:</span>
                        <p>We will have a countdown timer which will open the pool, you will then commit your BUSD
tokens to buy into the IAO’s</p>
                        <span>After Sale:</span>
                        <p>Claim the tokens you purchased. Add the contract address to your wallet to insure you have received the tokens</p>
                        <p>Done!</p>
                        <div className={styles.unlckBtn}>
                        <a href="https://astronautfinance.medium.com/how-does-astronaut-setup-idos-d12a4922015b"  target="_blank">
                        <button className="button">Read More</button>
                        </a>
                           
                        </div>
                    </Col>
                    <Col lg="6">
                    <h2>Want to launch your own IAO?</h2>
            <p>Launch your project with Astronaut, Binance Smart Chains most-loved launchpad, to bring your token directly to the most active and and growing community on BSC</p>
            <div className={styles.unlckBtn}>
            <a href="https://docs.google.com/forms/u/1/d/e/1FAIpQLScaMHYQrnfEU4pdpxqU2BcG6-DpsBTgayI9cU-Tg_pJRqaCIA/viewform"  target="_blank">
            <button className="button">Apply To Launch</button>
            </a>
           
            </div>
                    </Col>
                </Row>
            </Container>
        </section >
    </section>
}

export default DepositeScreen;