import React from "react";
import { Col, Row, Container } from "reactstrap";

import TokenMetrios from "../tokenMetrios/tokenMetrios";
import styles from "./tokenomics.module.scss";

const Tokenomics = () => (
  <div className={styles.tokenomics} id="tokenomics">
    <section>
      <Container>
        <Row>
          <Col lg="5" md={12}>
            <h2 className="text-center text-md-left section-title">
              NAUT <br /> TOKENOMICS
            </h2>
            <p className="text-center text-md-left">
              The native token of Astronaut is a BEP-20 token called NAUT, which
              is a hybrid utility token acting as both a deflationary auto yield
              and the Astronaut platform token for IDO tiers. A burn and tax fee
              is part of its smart contract allowing token holders to yield more
              tokens automatically directly to their wallets.
            </p>
            <a
              className="d-none d-md-inline"
              target="_blank"
              href="https://dex.apeswap.finance/#/swap?outputCurrency=0x05b339b0a346bf01f851dde47a5d485c34fe220c"
              rel="noreferrer"
            >
              <button className="button">Buy NAUT</button>
            </a>
          </Col>
          <Col lg={7} md="12">
            <TokenMetrios />
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default Tokenomics;
