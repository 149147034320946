import React from "react";
import { Col, Container, Row } from "reactstrap";

import pancakeswap from "../../images/icons/pancakeswap.png";
import cafeswap from "../../images/icons/cafeswap.png";
import bakeryswap from "../../images/icons/bakeryswap.png";
import apeswap from "../../images/icons/apeswap.png";
import babyswap from "../../images/babyswap-logo.png";
import styles from "./marketMakers.module.scss";

const SampleData = [
  {
    iconUrl: apeswap,
    name: "ApeSwap",
    link:"http://apeswap.finance/"
  },
  {
    iconUrl: babyswap,
    name: "BabySwap",
    link:"https://babyswap.finance/"
  },
  {
    iconUrl: cafeswap,
    name: "CafeSwap",
    link:"https://app.cafeswap.finance/"
  },
  {
    iconUrl: pancakeswap,
    name: "PancakeSwap",
    link:"https://pancakeswap.finance/"
  }
];

const MarketMarkers = () => (
  <div className={styles.market} id="market">
    <section>
      <Container>
        <Row>
          <Col>
            <h2 className="text-center section-title">
              AUTOMATED MARKET MAKERS INTEGRATED
            </h2>
            <Row className="justify-content-center">
              {SampleData.map(data => (
                <Col className={styles.maker} lg={3} md={6} xs={10} key={Math.random()}>
                  <a href={data.link} target="_blank">
                    <img alt={data.name} src={data.iconUrl} width="20" />
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default MarketMarkers;
