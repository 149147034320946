import React, { useState,useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from './rocketlist.module.scss';
import PoolCard from "../../components/poolCard/poolCard"; 

const RocketList = () => {

  const [] = useState();

  const SampleData = [


    {
      id: 12,
      description:"BIGFOOT is a decentralized community token with a hybrid dividend mechanism. Simply hold $FOOT and yield $BANANA (ApeSwap's native token).",
      status: "Open",
      currencyRateType:"BUSD/FOOT",
      currencyRate: "1 BUSD = 24200 FOOT",
      cap: "165k",
      acess: "Public",
      participants: "0",
      percentage: "0%",
      completion: "0/60000",
      website:"https://bigfoottoken.finance/",
      twitterLink:"https://twitter.com/Bigfoottoken",
      telegramLink:"https://t.me/bigfoottoken",
      discordLink:"#",
      mediumLink: "#"
    },
 
     
    // {
    //   id: 2,
    //   description:
    //     "Tapcoins (‘TTT’) are digital tokens built on the Ethereum Blockchain. They are the foundation for blockchain gaming on the Tap Platform. Tapcoins allow gamers to transfer their in-game currency from one game to the next – making it possible to get “paid to play!”",
    //   status: "Open in TBA",
    //   currencyRateType:"BUSD/TTT",
    //   currencyRate: "TBD",
    //   cap: "TBD",
    //   acess: "Private",
    //   participants: "xxxx",
    //   percentage: "100%",
    //   completion: "xxxxx/xxxxx",
    //   website:"https://www.tapproject.net/",
    //   twitterLink:"https://twitter.com/Tap_Coin",
    //   telegramLink:"https://t.me/tapcoin",
    //   discordLink:"https://discord.com/invite/5DyazH7vPj"
    // },
  ];

  return(
    <div className={styles.details}>
    <section>
    <div className={styles.panel}>
      <Row>
        <Col xs="12">
        
        </Col>
      </Row>
      <Row>
        {SampleData.map(data => <PoolCard 

                                  key={data.id} 
                                  {...data} />)}
      </Row>
      </div>

    </section>
    </div>
  );
}

export default RocketList;
