import React from "react";

import styles from "./navigation.module.scss";
import safe from "../../images/icons/safe.png";

const NavigationButton = ({ onClick, iconUrl, name, isActive }) => (
  <button
    onClick={() => onClick(name)}
    className={isActive && styles.active}
  >
    {iconUrl && <img src={iconUrl} width="20" alt="icon" /> }
    <span>{name}</span>
  </button>
);

const Navigation = ({ handleOnClick, activePage }) => {
  return (
    <div className={styles.navigation}>
      <NavigationButton
        onClick={handleOnClick}
        // iconUrl={safe}
        name="Pools"
        isActive={activePage === "Pools"}
      />
      {/* <NavigationButton
        onClick={handleOnClick}
        // iconUrl={safe}
        name="Tiers"
        isActive={activePage === "Tiers"}
      /> */}
      <NavigationButton
        onClick={handleOnClick}
        // iconUrl={safe}
        name="About"
        isActive={activePage === "About"}
      />
    </div>
  );
};

export default Navigation;
