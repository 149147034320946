import React from "react";
import { Col, Row, Container } from "reactstrap";

import styles from "./footer.module.scss";
import Logo from "../../images/typographic-gr.png";
import Helmet from "../../images/icons/helmet-large-gr.png";
import twitter from "../../images/icons/twitter.png";
import telegram from "../../images/icons/telegram.png";
import discord from "../../images/icons/discord.png";
import unknown from "../../images/icons/globe.svg";
import binance from "../../images/icons/binance.png";

const Footer = () => (
  <div className={styles.footer}>
    <section>
      <Container>
        <Row>
          <Col xs="12" className={styles.logos}>
            <a href="/">
              <img src={Helmet} className={styles.helmet} alt="helmet" />
              <img className={styles.logo} src={Logo} alt="Logo" />
            </a>
          </Col>
          <Col md={6} lg={7} className={styles.links}>
            <Row>
              <Col>
                <h4>Support</h4>
                <a
                  target="_blank"
                  href="https://astronaut-1.gitbook.io/astronaut/" rel="noreferrer"
                >
                  Whitepaper
                </a>
                <a
                  target="_blank"
                  href="https://astronaut-1.gitbook.io/astronaut/01.-what-is-astronaut/who-is-the-astronaut-team" rel="noreferrer"
                >
                  Team
                </a>
                <a target="_blank" href="https://astronautfinance.medium.com/" rel="noreferrer">
                  News
                </a>
              </Col>
              <Col>
                <h4>About</h4>
                <a target="_blank" href="https://github.com/astronauttoken/" rel="noreferrer">
                  Docs
                </a>
                <a href="#faq">
                  FAQ
                </a>
                <a
                  target="_blank"
                  href="./AstronautPrivacyPolicy.pdf" rel="noreferrer"
                >
                  Privacy
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={5} className={styles.social}>
            <h4>Stay Updated</h4>
            <div className={styles.buttons}>
              <a target="_blank" href="https://twitter.com/astronauttoken" rel="noreferrer">
                <button className="circle button">
                  <img src={twitter} alt="twitter" />
                </button>
              </a>

              <a target="_blank" href="https://t.me/astronaut_bsc" rel="noreferrer">
                <button className="circle button">
                  <img src={telegram} alt="telegram" />
                </button>
              </a>

              <a target="_blank" href="https://discord.gg/5DyazH7vPj" rel="noreferrer">
                <button className="circle button">
                  <img src={discord} alt="discord" />
                </button>
              </a>

              <a target="_blank" href="https://bscscan.com/token/0x05b339b0a346bf01f851dde47a5d485c34fe220c" rel="noreferrer">
                <button className="circle button">
                  <img src={binance} alt="binance" />
                </button>
              </a>

              <a target="_blank" href="https://astronaut.to/" rel="noreferrer">
                <button className="circle button">
                  <img src={unknown} alt="Tapcoin" />
                </button>
              </a>
            </div>
            <h5>
              Powered By the <br /> Binance Smart Chain
            </h5>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default Footer;
