import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import styles from "./depositPage.module.scss";
import config from "../../BlockchainProvider/config";
import { timers } from "jquery";


const DepositPage = (props) => {


  const [nautContract, setNautContract] = useState();
  const [nautBalance, setNautBalance] = useState("-");
  const [nautAllowance, setNautAllowance] = useState("-");
  const [stakeAmount, setStakeAmount] = useState("0");
  const [stakingContract, setStakingContract] = useState();
  const [stakedBalance, setStakedBalance] = useState();
  
  const [isDepositEnabled, setDepositEnabled] = useState(false);

  const [tier1Amount,setTier1Amount] = useState();
  const [tier2Amount,setTier2Amount] = useState();
  const [tier3Amount,setTier3Amount] = useState();
  const [tier4Amount,setTier4Amount] = useState();

  const [stakingStatus,setStakingStatus] = useState()

  const [depositBtnMode, setDepositBtnMode] = useState(0);
  useEffect(() => {
    if (props.nautContract) {
      setNautContract(props.nautContract);
      loadData(props.nautContract)
    }

    
  }, [props.nautContract])



  useEffect(() => {
    

    if(props.stakingContract){
      setStakingContract(props.stakingContract);
      initStakingData(props.stakingContract)
    }

  }, [props.stakingContract])




  useEffect(() => {
    if (props.account) {

      setDepositBtnMode(1)

    } else {
      setDepositBtnMode(0)
    }
  }, [props.account])


  

  const updateTicker=(isIcoOver,stakingStatus,startTime,endTime)=>{
    console.log({isIcoOver,startTime,endTime})
    let ticker = ""
    let time = -1;
    if(isIcoOver){
      ticker =  "Unstaking Enabled";
    }else{
        // if(now > startTime){
        //   time = startTime;
        //   ticker = "Starting in ";
        // }else{
        //   time = -1
        //   ticker = "Started"
        // }

        if(stakingStatus == 1){
          time = endTime;
          ticker = "Ends In";
        }else if (stakingStatus == 2){
          ticker ="Completed";

        }else{
          ticker = "Starting In"
          time = startTime;

        }

      

    }
    
    
    
    // else{
    //   let now = new Date().getTime()
    //   if(startTime > now){
    //     time = startTime;
    //     ticker= "Starting in"

    //   }else if(endTime < now && startTime< now){
    //     ticker= "Finished";
    //   }
    //   else{
    //     time = endTime
    //     ticker ="Starts in"

    //   }


    


    console.log("fcddd",{ticker,time})
    props.onTickerUpdate(ticker,time)
  }


  const initStakingData = async (contract) => {



    console.log("contt",contract.methods)

    let _isDepositEnabled =  await contract.methods.getStakingStatus().call();
    setStakingStatus(_isDepositEnabled)

    let expireTime =  await contract.methods.stakingStop().call();
    let startTime =  await contract.methods.stakingStart().call();

    let isICOOver =  await contract.methods.ICOover().call();
    let _stakedBalances =  await contract.methods.staker(props.account).call();

    

    let _tier1Amount =  await contract.methods.Tier1().call();
    let _tier2Amount =  await contract.methods.Tier2().call();
    let _tier3Amount =  await contract.methods.Tier3().call();
    let _tier4Amount =  await contract.methods.Tier4().call();

    setTier1Amount(_tier1Amount/1e8);
    setTier2Amount(_tier2Amount/1e8);
    setTier3Amount(_tier3Amount/1e8);
    setTier4Amount(_tier4Amount/1e8);
    


    props.onTierAmountLoaded([_tier1Amount/1e8,_tier2Amount/1e8,_tier3Amount/1e8,_tier4Amount/1e8])



    setStakedBalance(_stakedBalances.amount/1e8)
    updateTicker(isICOOver,_isDepositEnabled,startTime,expireTime)
    props.onIcoOver(isICOOver);
    props.onExpireTime(expireTime)
    setDepositEnabled(_isDepositEnabled)
   
  }

  const loadData = async (contract) => {
    let _balance = await contract.methods.balanceOf(props.account).call();
    let _allowance = await contract.methods.allowance(props.account, config.NEW_STAKING).call();




    setNautAllowance(_allowance / 10 ** 8)
      _balance = _balance / 10 ** 8;
      _balance = _balance.toFixed(4)

      console.log("_blanace", _balance)
      setNautBalance(_balance);

      if (_allowance > 0) {
        setDepositBtnMode(2)

      } else {
        setDepositBtnMode(1)

      }



  }






  const handleAllowance = async (amount) => {

      console.log("sdssdssdsds",nautContract)
    await nautContract.methods.approve(config.NEW_STAKING, "9000000000000000000000000000000000")
    .send({ from: props.account }).then(async(re)=>{

      await stakingContract.methods.stakeTokens(amount)
      .send({ from: props.account });
    });

  }


  const handleDeposit = async (amount) => {
    console.log("stakingStatus",stakingStatus)
    if(stakingStatus !=1){
      alert("Staking is not available")
      return

    }
    
    if(!isDepositEnabled){

      alert("Staking Not Available!")
      return
    }

    if(nautAllowance >= amount && stakingStatus == 1){
      
      await stakingContract.methods.stakeTokens(amount*10 ** 8)
      .send({ from: props.account });
    }else{
      handleAllowance(amount*10 ** 8);
    }

  

  }



  useEffect(() => {
    setInterval(() => {
      if (nautContract) {
        loadData(nautContract)
      }
    }, 2000)
  }, [])



  return <section className={styles.section1}>
    <Container>
      <h4 className={styles.hdr}>Deposit NAUT Tokens To Get Whitelisted</h4>
      <Row className={styles.card1}>
        <Col lg="4" md="12" xs="12">
          <p className={styles.title}>Your Balance</p>
          <h1>{nautBalance} Naut </h1>
          <span>4% Fee </span>

        </Col>
        <Col lg="4" md="12" xs="12">
          <Row>
            <Col lg="5" className={styles.moonBtn  + " showPointer"}   onClick={() => {
              
              handleDeposit(tier1Amount)
            }}>
              <p className={styles.moon} >{tier1Amount} <sup>MOON</sup></p>
            </Col>
            <Col lg="5" className={styles.marBtn  + " showPointer"} onClick={() => {
              handleDeposit(tier2Amount)
            }}>
              <p className={styles.mar}>{tier2Amount} <sup>MARS</sup></p>
            </Col>
          </Row>
          <Row>
            <Col lg="5" className={styles.starBtn   + " showPointer"} onClick={() => {
              handleDeposit(tier3Amount)
            }}>
              <p className={styles.star}>{tier3Amount} <sup>STAR</sup></p>
            </Col>
            <Col lg="5" className={styles.glxyBtn   + " showPointer"} onClick={() => {
              handleDeposit(tier4Amount)
            }}>
              <p className={styles.glxy}>{tier4Amount} <sup>GALAXY</sup></p>
            </Col>
          </Row>
        </Col>
        <Col lg="4" md="12" xs="12">
        {stakedBalance?<>  <p className={styles.title}>Your Stake </p>
          <h1>{stakedBalance} Naut </h1>
</>:null}
        </Col>
      </Row>

      {/* <h4 className={styles.hdr}>Tiers</h4>
      <Row className={styles.card2}>
        <Col xs="3" className={styles.col_moon}>
          <h5>MOON</h5>
          <span>Requirements</span>
          <p>{tier1Amount}</p>
          <span>Pool Weight</span>
          <p>20</p>
        </Col>
        <Col xs="3" className={styles.col_mar}>
          <h5>MARS</h5>
          <span>Requirements</span>
          <p>{tier2Amount}</p>
          <span>Pool Weight</span>
          <p>50</p>
        </Col>
        <Col xs="3" className={styles.col_star}>
          <h5>STAR</h5>
          <span>Requirements</span>
          <p>{tier3Amount}</p>
          <span>Pool Weight</span>
          <p>75</p>
        </Col>
        <Col xs="3" className={styles.col_galaxy}>
          <h5>GALAXY</h5>
          <span>Requirements</span>
          <p>{tier4Amount}</p>
          <span>Pool Weight</span>
          <p>125</p>
        </Col>
      </Row>
   
    */}
   
    </Container>
  </section>

}


export default DepositPage;
